.blog-cata-dtls h4 a{color: #2E3FA6;}
.recent-bg h3{color: #2E3FA6; font-weight:700; font-size:30px;}
.fade:not(.show) {  opacity: 1;}
.commercial-section {
    margin: 0px 0px;
    padding: 34px 32px;
    width: 100%;
    background: #f1f0f0;
    position: relative;
    border-radius: 12px;
    box-shadow: 0 10px 11px 0 rgb(0 0 0 / 18%);
    text-align: center;
    font-size: 15px;
    height: 437px;
}
.commercial-section .btn-warning:hover {
    color: #ffffff;
    background-color: #FF0202;
    border-color: #FF0202;
    border-radius: 0px;
    
}
.commercial-section .btn-warning {
    color: #212529;
    background-color: #FF0202;
    border-color: #FF0202;
    width: 100%;
    padding: 11px 0px;
    color: #ffffff;
    border-radius: 0px;
}
.commercial-section h2 {
    margin: 0px 0px;
    padding: 0px 0px;
    font-size: 20px;
    font-weight: 600;
    color: #1B2A89;
    line-height: 34px;
}

.commercial-section p {
    margin: 0px 0px 6px 0px;
    padding: 6px 0px 13px 0px;
    font-size: 15px;
    font-weight: 300;
    color: #262626;
    line-height: 23px;
    width: 100%;
    height: 192px;
    overflow: hidden;
}
.qualifymerchant-section {
    margin: 0px 0px;
    padding: 67px 0px;
    background: #0A0A0A;
    width: 100%;
}
.qualifymerchant-section h3 span {
    color: #F7931E;
}

.qualifymerchant-img {
    margin: 0px 0px;
    padding: 0px 0px;
    width: 100%;
    position: relative;
    border-radius: 36px 0px 36px 0px;
}
.qualifymerchant-img img {
    width: 100%;
    height: auto;
}
.qualifymerchant-section .btn-warning {
    color: #212529;
    background-color: #FF0202;
    border-color: #FF0202;
    padding: 11px 41px;
    color: #ffffff;
    margin-top: 12px;
    border-radius: 0px;
}
.merchantadvance-section h3 span {
    color: #F7931E;
}
.merchantadvance-section p {
    margin: 0px 0px 6px 0px;
    padding: 13px 0px;
    font-size: 16px;
    font-weight: 400;
    color: #262626;
    line-height: 28px;
    width: 100%;
}
.merchantadvance-section .btn-warning {
    color: #212529;
    background-color: #FF0202;
    border-color: #FF0202;
    padding: 11px 41px;
    color: #ffffff;
    margin-top: 12px;
    border-radius: 0px;
}
.constantlygrowing-section h2 {
    margin: 0px 0px;
    padding: 0px 0px;
    font-size: 26px;
    font-weight: 600;
    color: #1B2A89;
    line-height: 34px;
}
.constantlygrowing-section .btn-warning {
    color: #212529;
    background-color: #FF0202;
    border-color: #FF0202;
    padding: 11px 41px;
    color: #ffffff;
    margin-top: 12px;
    border-radius: 0px;
    border-radius: 0px;
}
.realestate-section2 {
    background: #0A0A0A;
}
.commercialbox {
    margin: 0px 10px;
    padding: 0px 0px;
    padding-bottom: 0px;
    width: 200px;
}
.commercial-section {
    margin: 0px 0px;
    padding: 10px 10px 20px;
    width: 100%;
    background: #f1f0f0;
    position: relative;
    border-radius: 12px;
    box-shadow: 0 10px 11px 0 rgb(0 0 0 / 18%);
    text-align: center;
    font-size: 15px;
    height: 100%;
}
.commercialbox .commercial-icon img {
    width: 100%;
}
.commercial-section p {
    margin: 0px 0px 6px 0px;
    padding: 6px 0px 13px 0px;
    font-size: 15px;
    font-weight: 300;
    color: #262626;
    line-height: 23px;
    width: 100%;
    height: 145px;
    overflow: hidden;
}
.commercial-section h2 {
    margin: 0px 0px;
    padding: 0px 0px;
    font-size: 16px;
    font-weight: 600;
    color: #1B2A89;
    line-height: 19px;
}
.commercialbox .commercial-icon {
    width: 100px;
    height: 100px;
    margin: 0px auto;
}
.merchantadvance-section h3 {
    margin: 0px 0px;
    padding: 0px 0px;
    font-size: 26px;
    font-weight: 600;
    color: #1B2A89;
    line-height: 34px;
}
.cashadvanceimg img {
    width: 100%;
    height: auto;
}
.requirements-section {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* background-image: url(../img/helpyou-bg.png); */
    position: relative;
    padding-bottom: 80px;
    padding-top: 80px;
    background-color: #0A0A0A;
    z-index: 99999;
}
.requirementsbox {
    position: relative;
    padding: 20px;
    margin: 0px 0px;
    background: #ffffff;
    min-height: 650px;
    overflow: hidden;
}
.requirementsbox h3 {
    margin: 0px 0px;
    padding: 16px 0px 0px 0px;
    font-size: 16px;
    font-weight: 600;
    color: #1B2A89;
    line-height: 20px;
}
.requirementsbox p {
    margin: 0px 0px 6px 0px;
    padding: 13px 0px;
    font-size: 15px;
    font-weight: 300;
    color: #262626;
    line-height: 24px;
    width: 100%;
}
.qualifymerchant-section h3 {
    margin: 0px 0px 18px 0px;
    padding: 0px 0px;
    font-size: 26px;
    font-weight: 600;
    color: #1B2A89;
    line-height: 34px;
}
.helps-text h2 {
    margin: 0px 0px;
    padding: 0px 0px;
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
    line-height: 34px;
}
.helps-text p {
    margin: 0px 0px 6px 0px;
    padding: 13px 0px;
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
    line-height: 28px;
    width: 100%;
}
.helps-text .btn-warning {
    color: #212529;
    background-color: #FF0202;
    border-color: #FF0202;
    padding: 11px 41px;
    color: #ffffff;
    margin-top: 12px;
    border-radius: 0px;
    }
.helps-text .btn-warning:hover {
    color: #ffffff;
    background-color: #FF0202;
    border-color: #FF0202;
    border-radius: 0px;
    }
.requirementsbox ul li {
    margin: 0px 0px 6px 0px;
    padding: 0px 0px;
    font-size: 15px;
    font-weight: 300;
    color: #262626;
    line-height: 21px;
    width: 100%;
	list-style:circle;
}
.requirementsbox {
    position: relative;
    padding: 20px;
    margin: 0px 0px;
    background: #ffffff;
    height: 100%;
    overflow: hidden;
}
/* contact page */

.text-form 
{    height: 110px !important;}

.cashadvanceimg img{
    width: 100%;
}

.tm-about-us-img-bg {
    position: absolute;
    bottom: -15px;
    left: -15px;
    z-index: 1;
}
.tm-about-us-img {
    position: relative;
}


.Get-Start-section {
    background-image: url('/public/img/loanbanner.png');
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.65);
}


.area-padding {
    padding: 100px 0;
}

.get-content p {
    padding: 2% 20%;
}



.tm-header-btn1 span {
    position: relative;
    z-index: 1;
    transition: all ease-in-out .4s;
    text-transform: capitalize;
    color: #fff;  
}

.tm-header-btn1 {
    position: relative;
    height: 3rem;
    background-color: #FF0202;
    line-height: 1;
    color: #fff;
    border: 1px solid #FF0202;
    /* transition: all ease-in-out .4s; */
    display: inline-block;
    border-radius: 0px;
}

.tm-header-btn1:hover {

    background-color: #FF0202;
    color: #fff;
    border: 1px solid #FF0202;

}

.service-page-content{
    text-align: justify;
}


.service-page-content p{
    color: #fff;
}
.service-page-content h2{
    color:#FF0202;
    text-align: left;
}

.tm-sec-title h3{
    color: #FFF;
    font-weight: 700;
}

.tm-sec-title span{
    color: #FF0202;
    font-weight: 700;
}

.con-img{
    background-image: url("/public/img/contact_photo.jpg");
  height: 500px;
}


.requirements-img img {
    height: 400px !important;
    object-fit: cover !important;
    object-position: center center;
  }


/* ===================Responsive ======================*/
@media only screen and (max-width: 991px) {

    .con-img {height: 365px;}
    .area-padding {padding: 15px 0; }
    .merchantadvance-section .btn-warning {margin-bottom: 10px;}
    .requirementsbox {min-height: 490px;}
    .requirements-img img {width: 100%;}
    .get-content p { padding: 0px;}
    .tm-header-btn1 span {color: #fff;}
    .service-page-content p {text-align: initial; margin-top: -40px; padding-top: 23px;}
    .service-page-content {text-align: initial; padding-top: 37px; }
    .about-page-image {padding-top: 15px;}
    .service-page-content h2 {padding-bottom: 10px;}
    .requirementsbox{margin-bottom: 15px;}
    .requirements-img img {height: auto !important;}
    .commercial-section p {font-size: 14px;line-height: 20px;height: auto;}

 }