footer {
  background-color: #0a0a0a;
  padding: 50px 0px 20px;
}

.con-img{
  background-image: url("/public/img/contact_photo.jpg");
  height: 500px;
}

.ra {
  border: 1.7px solid #fff;
  margin-bottom: 100px;
}
.ftr-social i {
  background-color: #fb0505;
  color: #060606;
  padding: 9px 12px;
  /* border-radius: 50%; */
  margin-right: 11px;
  border-radius: 11%;
}
.ftr-social .fa-x-twitter {
  padding: 9px 9px;
}
.footer-1 h3 {
  color: #FF0202;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.location i {
  color: #fb0505;
}
.ftr-nav{
  line-height: 25px;
  font-weight: 500;
}
.ftr-nav-ul li {
  padding: 5px 4px 5px 0;
  display: inline-flex;
}
.ftr-nav-ul li a {
  /* border-right: 1px solid #fff; */
  padding: 0 0px 0 0;
  color: #FFF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
hr {
  color: #ffffff;
  border: 1.5px solid #ffffff;
  margin-bottom: 100px;
}


.copy {
  padding-top: 50px;
}

.copy h4,
.copy a {
  color: #FFF;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.copy h5{
  color: #FFF;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-align: right;
}


.ftr-content h6 {
  color: var(--White, #fff);
  -webkit-text-stroke-color: #ff0202;
  -webkit-text-stroke-width: 0.6020728349685669px;
  font-size: 24.459px;
  font-style: normal;
  font-weight: 600;
 
}

.ftr-content p{
  margin-bottom: 0px;
  color: #FFF;
}
.text-white{
margin-top: -7px;
text-decoration: none;
}
/* .ftr-content h6 span {
  font-size: 24px;
  font-weight: 700;
  color: #fb0505;
} */
@media only screen and (max-width: 767px) { 
  .copy, .copy h5{
    text-align: center;
    padding-top: 0; 
  }
  
}




