.about {
  background-color: rgba(10, 10, 10, 1);
  overflow: hidden;
}

.about-hed {
  padding-left: 22%;
  padding-right: 11%;
  text-align: justify;
  padding-top: 42px;
}

.about-hed span {
  color: #ff0202;
  font-size: 50px;

  font-weight: 700;
}

.about-hed p {
  font-size: 17px;
  color: #fff;
  font-weight: 500;
}

.about-hed h1 {
  font-size: 45px;
  color: #fff;
  font-weight: 700;
}

.rj img {
  height: 623px;
  width: 100%;
}

.ra-about-story p {
  font-size: 17px;
  color: #fff;
  font-weight: 500;
  padding-left: 22px;
  margin: 0px;
  margin-top: -11px;
  padding-bottom: 51px;
}

/* ===================Responsive ======================*/

@media only screen and (max-width: 991px) {
  .about-hed span {
    font-size: 43px;
  }
  .about-hed h1 {
    font-size: 40px;
  }
  .about-hed p {
    font-size: 13px;
    text-align: justify;
  }
  .about-hed {
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 33px;
  }
  .ra-about-story p {
    font-size: 13px;
    padding-left: 8px;
    margin: 19px 0px 0px;
    padding-bottom: 0px;
  }
  .rj img {
    padding: 0 7px;
    object-fit: cover;
    height: 100%;
  }
  .con-text {
    width: 65%;
    bottom: 0px;
    height: 176px;
  }
  .con-hed h1 {
    font-size: 35px;
  }
  .con-hed {
    padding-top: 21px;
    padding-left: 0px;
  }
}

@media (min-width: 991px) and (max-width: 1024px) {
  .about-hed p {
    font-size: 15px;
  }
  .ra-about-story p {
    font-size: 15px;
    padding-left: 65px;
  }
}
