.ra-con {
  position: relative;
}

.ra-con img{
 
    width: 100%;
    height: 500px;
    object-fit: cover;

}
.con-text {
  background: #810000eb;
  mix-blend-mode: hard-light;
  width: 45%;
  align-items: end;
  position: absolute;
  right: 0;
  bottom: 0px;
  height: 280px;
}

.con-hed {
  padding-top: 30px;
  padding-left: 30px;
}
.con-hed h1 {
  color: #fff;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}

.ra-hed p {
  padding-right: 26%;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 128%;
}

/* from */

.call-request-form {
  max-width: 721px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.form-row input {
  padding: 10px;
}

.form-row textarea {
  padding-bottom: 85px;
  padding-left: 9px;
}
.form-title {
  text-align: left;
  margin-bottom: 0px;
  padding-top: 30px;
  color: #fff;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
}

.form-title span {
  color: #ff0202;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
}

.submit-btn {
  align-self: center;
  margin-top: 20px;
}

.status-message {
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-top: 1rem;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.con-ra-btn {
  padding-left: 43%;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
}

.con-btn {
  background-color: #000;
  color: #fff;
  border: 3px solid #fff;
  /* background-size: 23px; */
  padding: 10px 15px 10px 15px;
}

@media (max-width: 480px) {
  .call-request-form {
    max-width: 100%;
  }
}

/* ===================Responsive ======================*/

@media only screen and (max-width: 991px) {
  .con-img {
    height: 345px;
  }
  .form-row {
    padding-bottom: 10px;
  }
  .con-ra-btn {
    padding-left: 32%;
  }
  .form-title {
    font-size: 40px;
  }
  .form-title span {
    font-size: 40px;
  }
}
