/* //home// */
.tm-mainnav-panel {
  display: flex;
  width: 100%;
}
#bg-video {
  object-fit: cover;
  width: 100%;
  height: 97.5vh;
  position: relative;
  /* top: 0;
  left: 0;
  z-index: -1; */
}
.ra-home {
  position: relative;
}
.ra-text {
  /* background: #ff0202;
  
    mix-blend-mode: multiply; */
  background: #810000eb;
  mix-blend-mode: hard-light;
  width: 45%;
  align-items: end;
  position: absolute;
  right: 0;
  bottom: 0px;
  height: 300px;
}

.ra-hed {
  padding-top: 30px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  gap:5;
}


.ra-hede {
  padding-top: 23px;
  padding-left: 10px;
}

.ra-hed-panel {
  margin-right: 5px;
}
.ra-hede h1 {
  color: #fff;

  font-family: Inter;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}

.ra-hede p {
  padding-right: 26%;
  color: #fff;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 128%;
}

/* loan */

.mainlone {
  background-color: red;
  padding-bottom: 6%;
  margin-top: -6px;
}

.ra-lone {
  padding-top: 5%;
  padding-bottom: 1%;
}
.ra-lone-hed {
  text-align: center;
  color: #fff;
  font-size: 38px;
  font-weight: 700;
}

/* .am-lone-title{
    text-align: center;
    margin-bottom: 25px;
} */

.column {
  float: left;
  width: 30.3%;
  margin-bottom: 16px;
  padding: 0 8px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 8px;
  border-radius: 0;
  border: black;
}

.about-section {
  padding: 50px;
  text-align: center;
  background-color: #474e5d;
  color: white;
}

/* .container {
    padding: 0 16px;
  } */

.container::after,
.row::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}

.ra-card1 {
  background-color: #0a0a0a;
  min-height: 135px;
}

/* about */

.ra-about {
  background-color: #0a0a0a;
  width: 100%;
  margin-top: -5px;
  /* position: relative; */
  overflow: hidden;
}

.am-aboutText {
  margin-top: 10%;
  margin-right: 0%;
  margin-left: 19%;
}

.am-aboutText span {
  color: red;
  font-size: 53px;
  font-weight: 700;
  margin-bottom: 18px;
  padding-left: 25%;
}

.am-aboutText h1 {
  color: white;
  font-size: 46px;
  font-weight: 700;
  margin-bottom: 6px;
}

.am-aboutText h2 {
  color: #ff0202;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 6px;
  padding-left: 8%;
}

.am-aboutText p {
  color: white;
  font-size: 13px;
  font-weight: 500;
  /* text-align: justify; */
  padding-right: 25%;
  text-align: center;
}

/* .ra-about-title p {
    color: rgba(255, 2, 2, 0.50);
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    padding-top: 22px;
  } */

/* .am-aboutimg img{
    height: 70vh;
} */

/* .img-fluid {
    max-width: 100%;
    height: 501px;
  } */

/* contact */

.contact-img {
  position: relative;
}

.contact-img img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.ra-title-c h1 {
  color: white;
  font-size: 56px;
  font-weight: 700;
  text-align: center;
  padding-top: 6%;
}

.ra-title-c span {
  color: red;
  font-size: 56px;
  font-weight: 700;
  text-align: center;
  padding-top: 6%;
}

.ra-title-c p {
  color: white;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.btn-c {
  border-radius: 0;
  border: 2px solid #fff;
}

/* body {
        font-family: Arial, sans-serif;
        line-height: 1.6;
        color: #333;
        padding: 0 20px;
        
      }
      
      .container {
        max-width: 800px;
        margin: 0 auto;
        text-align: center;
        background-color:red;
      } */

.ra-part2 {
  background-color: red;
  margin-top: 100px;
}

.ra-con-title h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.ra-form {
  padding-right: 6%;
}

/* ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  } */

/* li {
    margin-bottom: 20px;
    text-align: left;
  } */

/* .step {
    display: inline-block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    
    font-weight: bold;
    font-size: 1.2em;
    
    color: #fff;
    border-radius: 50%;
  } */
.ra-text {
  color: #fff;
}
.ra-box1 {
  border-top: 5px solid #fff;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  display: inline-block;
  border-left: 5px solid #fff;
  padding: 10px 5px 5px 5px;
  color: #fff;
}

.con-content-5 {
  border-right: 5px solid #fff;
}

.ra-con-title h1 {
  color: #fff;
  font-size: 52px;
  font-weight: 700;
  padding-left: 5px;
  padding-left: 9%;
}

.ra-con-title p {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  /* width: 581px; */
}

.ra-con-box {
  padding: 0px 30px 20px 30px;
}
.tm-ra-con-box-title {
  position: relative;
  padding-left: 0;
  display: block;
  min-height: 44px;
}
.tm-ra-con-box-title span {
  position: absolute;
  left: 18px;
  top: 2px;
  line-height: 20px;
}
/* .tm-ra-con-box-panel p{
    padding-top: 25px;
  } */
.tm-ra-con-box-panel li {
  list-style: none;
}
.ra-content {
  display: inline-block;
  border-left: 5px solid #fff;
  border-right: 5px solid #fff;
  padding: 10px 5px 5px 5px;
  color: #fff;
}

.tm-ra-con-box-panel1 {
  border-right: 5px solid #fff;
}

/* contact1 */

.ra-contact1-img img {
  width: 630px;
  height: 500px;
}

.ra-box {
  line-height: 12px;
}

.btn-c1 {
  border-radius: 0px;
  border: 2px solid #fff;
}
.ra-box i {
  font-size: 30px;
  color: red;
  padding-right: 10px;
}

.ra-box p {
  font-size: 18px;
}

.ra-about-btn {
  padding-left: 43%;
  color: #fff;
  padding-top: 20px;
}

/* navber */

/* .tm-na {
      padding-left: 30%;
    } */

.mainnav {
  background-color: #0a0a0a;
}
.ra-dpd .nav-link {
  color: #fff;
}
.ra-nab-img {
  margin-top: -27px;
  border-left: 1.5px solid #ff0202;
  padding-left: 10px;
}

.ra-nab-hed h6 {
  color: var(--White, #fff);
  -webkit-text-stroke-color: #ff0202;
  -webkit-text-stroke-width: 0.6020728349685669px;
  font-size: 26.459px;
  font-style: normal;
  font-weight: 600;
  margin-top: -10px;
}
.ra-dpd .nav-item .nav-link {
  color: #fff;
}

a#subNavDropdown {
  color: #fff;
}

.dropdown-menu.show {
  background-color: #000;
}

a.dropdown-item {
  color: #fff;
}

.btn-c3 {
  padding-left: 43%;
  color: #fff;
  padding-top: 20px;
}

.btn-c3-ra {
  border-radius: 0;
  border: 2px solid #fff;
}

.ra-whyChoose {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #0a0a0a;
  overflow: hidden;
}

.ra-whyChoose-title {
  color: #fff;
}

.ra-whyChoose-title span {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
}

.ra-whyChoose-title h3 {
  color: #ff0202;
  font-size: 50px;
  font-weight: 700;
}

.ra-whyChoose-title p {
  /* text-align: justify; */
  padding-right: 25%;
  font-size: 16.5px;
  font-weight: 400;
  /* line-height: 24px; */
}

.ra-whyChoose-btn {
  color: #fff;
  padding-top: 20px;
}

.me-auto.my-2.my-lg-0.navbar-nav.navbar-nav-scroll {
  max-width: 100%;
  padding-left: 21%;
}

/* ===================Responsive ======================*/

@media only screen and (max-width: 991px) {
  .tm-mainnav-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ra-hed {
    padding-top: 5px;
    padding-left: 15px;
  }

  .dropdown-menu.show {
    padding-left: 10px;
  }
  .ra-nab-img {
    margin-top: 0;
  }

  .tm-mainnav-panel .navbar-nav {
    max-height: 100% !important;
    padding-left: 15px !important;
  }
  .tm-mainnav-panel .btn-c {
    margin-left: 15px;
  }
  .tm-mainnav-panel .navbar-toggler-icon {
    background-image: url("/public/img/menuBar.png");
  }
  .ra-text {
    width: 70%;
    height: 229px;
    bottom: 0px;
  }
  .ra-hede h1 {
    font-size: 26px;
  }
  .ra-contact1-img img {
    width: 100%;
  }
  .ra-hede p {
    padding-right: 0;
    font-size: 11px;
  }
  .ra-hede {
    padding-left: 18px;
    padding-top: 14px;
}
  .ra-lone-hed {
    font-size: 27px;
  }
  .am-aboutText span {
    font-size: 36px;
    padding-left: 33%;
  }
  .am-aboutText h1 {
    font-size: 27px;
    text-align: center;
  }
  .am-aboutText {
    margin-left: 3%;
  }
  .am-aboutText h2 {
    font-size: 21px;
    padding-left: 0%;
    text-align: center;
  }
  .am-aboutText p {
    padding-right: 7%;
    padding-left: 5%;
  }
  .ra-about-btn {
    padding-left: 35%;
    padding-top: 12px;
    padding-bottom: 10px;
  }
  .ra-title-c h1 {
    font-size: 31px;
  }
  .ra-title-c span {
    font-size: 30px;
  }
  .ra-title-c p {
    font-size: 12.5px;
    font-weight: 600;
  }
  .btn-c3 {
    padding-top: 12px;
  }
  .ra-con-title h1 {
    font-size: 31px;
    padding-left: 5%;
  }
  .ra-con-title p {
    font-size: 12.5px;
    font-weight: 600;
    padding: 15px;
    margin-top: -30px;
  }
  .ra-con-box {
    padding: 2px 44px 9px 22px;
  }
  .content {
    border-left: 4px solid #fff;
    padding: 8px 2px 1px 6px;
  }
  .ra-box1 {
    border-top: 4px solid #fff;
    margin-right: 8px;
  }
  .ra-part2 {
    margin-top: 26px;
  }
  .tm-ra-con-box-panel1 {
    border-right: 0px;
    margin-right: 50%;
  }
  .ra-whyChoose {
    padding-top: 17px;
    padding-bottom: 8px;
  }
  .ra-whyChoose-title span {
    font-size: 36px;
  }
  .ra-whyChoose-title h3 {
    font-size: 38px;
  }
  .ra-whyChoose-title {
    padding-left: 15px;
  }
  .ra-whyChoose-title p {
    padding-right: 2px;
    font-size: 13.5px;
  }

  .ra-whyChoose-btn {
    padding-left: 11px;
  }

  /* ===============footer ================*/

  .ra {
    margin-bottom: 35px;
  }
  .ftr-nav {
    padding-left: 22px;
  }
  .footer-1 h3 {
    font-size: 20px;
    padding-top: 10px;
  }
  .ftr-nav-ul li {
    margin-left: -10px;
  }

  /* ===============navber ================*/
}

@media (min-width: 480px) and (max-width: 991px) {
  .ra-nab-hed h6 {
    font-size: 20.459px;
    margin-top: 9px;
  }
  #navbarScroll {
    top: 73px;
  }
  .ra-nab-img img {
    width: 100px;
  }
  .ra-card1 {
    min-height: 176px;
  }
  #bg-video {
    height: 51.5vh;
  }

  
}

@media only screen and (max-width: 480px) {
  #navbarScroll {
    top: 70px;
  }
  .ra-nab-hed h6 {
    font-size: 15px;
  }

  .ra-nab-img img {
    width: 86.2px;
  }
  .ra-contact1-img img {
    height: 209px;
  }
  #bg-video {
    height: 46.5vh;
  }
  .btn-c3 {
    padding-left: 29%;
  }
  .ra-hed {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .ra-box1 .col {
    flex: auto;
  }
  .tm-ra-con-box-panel1 {
    margin-right: 0;
  }
  .me-auto.my-2.my-lg-0.navbar-nav.navbar-nav-scroll {
    padding-left: 4%;
  }
  #navbarScroll {
    position: absolute;
    left: 0;
    top: 90px;
    background-color: #0a0a0a;
    width: 100%;
    z-index: 99;
    padding-bottom: 20px;
    top: 74px;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .ra-text {
    width: 57%;
    padding-top: 6px;
  }
  .btn-c3 {
    padding-left: 41%;
  }
  /* #bg-video {height: 40.5vh;} */
  .am-aboutimg img {
    height: 346px;
  }
  .ra-nab-hed h6 {
    font-size: 18.459px;
    margin-top: -5px;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    font-size: 12px;
  }
  .ra-form {
    padding-right: 0;
  }
}

@media (min-width: 991px) and (max-width: 1024px) {
  .am-aboutimg img {
    height: 464px;
  }
  .ra-nab-hed h6 {
    font-size: 19.459px;
    margin-top: -13px;
    padding-left: 5px;
  }
  .ra-nab-img img {
    width: 60%;
    padding-top: 20px;
  }
  .me-auto.my-2.my-lg-0.navbar-nav.navbar-nav-scroll {
    padding-left: 0;
  }
  .ra-hed {
    padding-left: 5px;
    padding-top: 9px;
  }
  #bg-video {
    height: 60.5vh;
  }
  .ra-form {
    padding-right: 0;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    font-size: 15px;
  }
  .am-aboutText p {
    padding-right: 9%;
  }
  .ra-about-btn {
    padding-left: 46%;
    padding-top: 17px;
    padding-bottom: 10px;
  }
}
@media (min-width: 767px) and (max-width: 1024px) {
  .ra-hed {
    display: block;
  }
}

@media (min-width: 1024px) and (max-width: 1300px) {
  .ra-nab-hed h6 {
    font-size: 22.459px;
  }
  .ra-nab-img img {
    width: 90%;
  }
  .ra-hed {
    padding-top: 28px;
    padding-left: 23px;
  }
  .me-auto.my-2.my-lg-0.navbar-nav.navbar-nav-scroll {
    padding-left: 5px;
  }
  .ra-form {
    padding-right: 0;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    font-size: 15px;
  }

  #bg-video {height: 60.5vh;}
  .ra-hede {padding-top: 10px;padding-left: 10px;}
}
