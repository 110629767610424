.blog-img {
  background-image: url(/public/img/blog-banner.jpg);
  height: 500px;
}
.ra-blog-banner {
  position: relative;
}

.ra-blog-banner img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.blog-text {
  background: #810000eb;
  mix-blend-mode: hard-light;
  width: 45%;
  align-items: end;
  position: absolute;
  right: 0;
  bottom: -25px;
  height: 308px;
}

.blog-hed {
  padding-top: 30px;
  padding-left: 30px;
}
.blog-hed h1 {
  color: #fff;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}

.ra-hed p {
  padding-right: 26%;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 128%;
}

/*--------------------------------------------------------------
# Blog Page
--------------------------------------------------------------*/
/* Blog Section - Blog Page
------------------------------*/
.ra-blog {
  background-color: rgba(10, 10, 10, 1);
}



.title-blog2 a {
  font-size: 18px;
  font-weight: 600;
  color: rgba(255, 2, 2, 1);
  line-height: 24px;
  display: inline-block;
  text-decoration: auto;
}

.title-blog1 {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin: 0px;
  padding-bottom: 10px;
}

.ra-blog1 {
  padding-bottom: 20px;
}

.ra-title-blog a {
  font-size: 24px;
  font-weight: 600;
  color: rgba(255, 2, 2, 1);
  line-height: 24px;
  display: inline-block;
  text-decoration: auto;
}

.ra-title-blog p {
  font-size: 16.5px;
  font-weight: 400;
  color: #fff;
  padding-right: 20px;
}

.blog .posts-list .post-img {
  /* max-height: 240px; */
  /* overflow: hidden; */
  border-radius: 0%;
}

.blog .posts-list .post-category {
  font-size: 16px;
  color: rgba(var(--default-color-rgb), 0.6);
  margin-bottom: 10px;
}

.blog .posts-list .title {
  font-size: 22px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .posts-list .title a {
  font-size: 24px;
  font-weight: 600;
  color: rgba(255, 2, 2, 1);
  line-height: 28px;
  display: inline-block;
  text-decoration: auto;
}

.ra-title p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  padding-right: 9px;
}

.blog .posts-list .post-author-img {
  width: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.blog .posts-list .post-author {
  font-weight: 600;
  margin-bottom: 5px;
  color: #fff;
}

.blog .posts-list .post-date {
  font-size: 14px;
  color: rgba(var(--default-color-rgb), 0.6);
  margin-bottom: 0;
}

.blog .pagination {
  margin-top: 30px;
  color: rgba(var(--default-color-rgb), 0.6);
}

.blog .pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .pagination li a {
  color: rgba(var(--default-color-rgb), 0.6);
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .pagination li.active,
.blog .pagination li:hover {
  background: var(--accent-color);
  color: var(--contrast-color);
}

.blog .pagination li.active a,
.blog .pagination li:hover a {
  color: var(--contrast-color);
}

.ra-post-img img {
  height: 361px;
}

/* ===================Responsive ======================*/

@media only screen and (max-width: 991px) {
  .blog-img {
    height: 345px;
  }
  .blog-text {
    width: 61%;
    bottom: 0px;
    height: 201px;
  }
  .blog-hed h1 {
    font-size: 40px;
  }
  .ra-post-img img {
    height: 280px;
    width: 100%;
  }
  .post-img img {
    width: 100%;
  }

  .ra-blog-banner img{
    height: 45.5vh;;
  }
  
}




@media only screen and (max-width: 767px) {
  
  .ra-hed p {font-size: 11px;}
 
}

@media (min-width: 991px) and (max-width: 1024px) {
  .ra-post-img img {
    height: 295px;
  }
}


