h1.ra-head {
    margin-top: 2vw;
    text-align: center;
    font-size: 33px;
    margin-bottom: 15px;
}

.ra-login{
    margin-top: 2vw;
    text-align: center;
    font-size: 33px;
    margin-bottom: 15px;  
}

.form{
    text-align: center;
}



.form-group input{
    width: 52%;
    padding-bottom: 5px;
    margin-bottom: 10px;
    padding-top: 5px;
}


.btn.btn-primary {
    margin-top: 19px;
    padding: 10px 25px 10px 25px;
}

.my-1 {
    text-align: center;
    padding: 17px 0px 63px 0px;
}



.card1 {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* margin: 8px; */
    border-radius: 0;
    border: black;
    width: 50%;
    /* text-align: center; */
    /* justify-content: center; */
    /* align-items: center; */
    height: 50%;
    margin: 20vh 0Vh 10vh 56vh;
    padding-top: 4px;
}




@media only screen and (max-width: 786px) { 

    .card1 {
      
        margin: 20vh 0Vh 10vh 1.5vh;
        width: 95%;
       
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .card1 {
      
        margin: 20vh 0Vh 10vh 26.5vh
        /* width: 95%; */
       
    }
}
@media (min-width: 1025px) and (max-width: 1300px) {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
    .card1 {
      
        margin: 15vh 0Vh 10vh 32vh;
        /* width: 95%; */
       
    }
}